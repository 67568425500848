import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input, HostListener, Directive } from '@angular/core';
import { StreamInfo } from 'streaming-lib';
// import { Observable, BehaviorSubject } from 'rxjs';
import { StreamService } from '../stream.service';
import { LibMonkeywayService } from 'lib-monkeyway';
import 'hammerjs';
import { timer, Observable, Subscription, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-livestream',
  templateUrl: './livestream.component.html',
  styleUrls: ['./livestream.component.css']
})
export class LivestreamComponent implements OnInit {

  private panInterval = 30;

  // private _stream: MediaStream;
  // @Input() set stream(value: MediaStream) {
  //   if (this._stream === value) return;
  //   this._stream = value;
  //   this.onStreamUpdated();
  // }

  private _info: StreamInfo;
  @Input() set track(value: StreamInfo) {
    if (this._info === value) return;
    this._info = value;
    if (value) {
      this.uiUrl = `http://${value.ip}:8085`;
    } else {
      this.uiUrl = null;
    }
    this.onStreamUpdated();
  }


  private uiUrl: string;
  private connectionKey: string;
  private connectionKeys: string[];
  private connectionCodes: string[];
  private connected$: Observable<boolean> = this.streamService.connected$;

  public hasStream$ = new BehaviorSubject<boolean>(false);

  @ViewChild("videoContainer", { read: ElementRef, static: false }) videoContainer: ElementRef;
  @ViewChild("remoteVideo", { read: ElementRef, static: false }) remoteVideo: ElementRef;

  // constructor(private streamService: StreamService) { }
  constructor(private streamService: StreamService, private controlService: LibMonkeywayService) { }

  ngOnInit() {
    this.connectionKey = this.getQueryParameter("connectionKey");
    this.connectionKeys = null;
    this.connectionCodes = null;
  }

  async start($event) {
    $event.stopPropagation();
    this.streamService.start('https://newstreaming.demo.monkeyway.de', this.connectionKey);   
  }

  stop($event) {
    $event.stopPropagation();
    this.streamService.stop();
  }

  fullscreen($event) {
    // const videoContainer = document.getElementById('videoContainer');
    if (document.fullscreenElement) {
      document.getElementById('fullscreen').setAttribute('src', 'assets/fullscreen_white.png');
      document.getElementById('fullscreen').setAttribute('title', 'Enter fullscreen mode');
      document.exitFullscreen();


    }
    else {
      document.getElementById('fullscreen').setAttribute('src', 'assets/exit_fullscreen_white.png');
      document.getElementById('fullscreen').setAttribute('title', 'Exit fullscreen');

      if (this.videoContainer.nativeElement.requestFullscreen)
        this.videoContainer.nativeElement.requestFullscreen();

    }
  }

  private getQueryParameter(key: string): string {
    const parameters = new URLSearchParams(window.location.search);
    return parameters.get(key);
  }

  private onStreamUpdated() {
    this.connectionKeys = [];
    this.connectionCodes = [];

    if (!this._info || !this._info.track) {
      console.log('video stopped');
      this.hasStream$.next(false);
      if (this.remoteVideo && this.remoteVideo.nativeElement) {
        this.remoteVideo.nativeElement.pause();
        this.remoteVideo.nativeElement.srcObject = null;
        this.stopMoveTimer();
      }
    } else {
      if (this._info.connectionKeyInfos) {
        this._info.connectionKeyInfos.forEach(k => {
          const url = `${window.location}/?connectionKey=${k.connectionKey}`;
          this.connectionKeys = this.connectionKeys.concat(url);
          this.connectionCodes = this.connectionCodes.concat(k.connectionCode);
        });
      }

      // this.connectionKeys = this._info.connectionKeys;
      console.log('starting video')
      this._info.track.onmute = () => console.log('muted');
      this._info.track.onunmute = () => console.log('unmuted');
      this.remoteVideo.nativeElement.srcObject = new MediaStream([this._info.track]);
      this.remoteVideo.nativeElement.play()
        .then(() => {
          console.log('video started');
          this.hasStream$.next(true);
          this.startMoveTimer();
          // if (this.videoContainer.nativeElement.requestFullscreen)
          //   this.videoContainer.nativeElement.requestFullscreen();
        })
        .catch(error => {
          console.log('could not start video');
          console.log(error);
        });
    }
  }

  private startMoveTimer() {
    this.moveTimer = timer(this.panInterval, this.panInterval);
    this.moveTimerSubscription = this.moveTimer.subscribe(this.onMoveTimer.bind(this));
  }

  private stopMoveTimer() {
    if (this.moveTimerSubscription) {
      this.moveTimerSubscription.unsubscribe();
      this.moveTimer = null;
    }
  }

  private currentIsPanning;
  private nextIsPanning;
  private currentPanDeltaX;
  private currentPanDeltaY;
  private nextPanDeltaX;
  private nextPanDeltaY;
  private velocityX;
  private velocityY;
  private currentPinchScale;

  private moveTimer: Observable<number>;
  private moveTimerSubscription: Subscription;

  private onMoveTimer() {
    if (
      this.currentPanDeltaX === this.nextPanDeltaX &&
      this.currentPanDeltaY === this.nextPanDeltaY &&
      this.currentIsPanning === this.nextIsPanning
    ) {
      return;
    }

    const movementScalingFactor =
      50 /
      (this.remoteVideo.nativeElement.clientWidth > this.remoteVideo.nativeElement.clientHeight
        ? this.remoteVideo.nativeElement.clientWidth
        : this.remoteVideo.nativeElement.clientHeight);
    const relativeWidth = (this.nextPanDeltaX - this.currentPanDeltaX) * movementScalingFactor;
    const relativeHeight = (this.nextPanDeltaY - this.currentPanDeltaY) * movementScalingFactor;

    this.controlService.move(this.nextPanDeltaX, this.nextPanDeltaY, !this.nextIsPanning);
    this.currentPanDeltaX = this.nextPanDeltaX;
    this.currentPanDeltaY = this.nextPanDeltaY;
    this.currentIsPanning = this.nextIsPanning;
  }

  private onPan(evt) {
    if (!this.nextIsPanning) {
      this.currentPanDeltaX = evt.deltaX;
      this.currentPanDeltaY = evt.deltaY;
    }

    this.nextIsPanning = !evt.isFinal;

    const width = this.remoteVideo.nativeElement.clientWidth;
    const height = this.remoteVideo.nativeElement.clientHeight;
    const x = evt.pointers[0].layerX / width;
    const y = evt.pointers[0].layerY / height;

    this.nextPanDeltaX = x;
    this.nextPanDeltaY = y;
    this.velocityX = evt.velocityX;
    this.velocityY = evt.velocityY;
  }

  private onPinch(evt) {
    const zoom = this.currentPinchScale - evt.scale;
    this.currentPinchScale = evt.scale;

    // this.vwService.zoom(zoom);
  }

  private onTap(evt) {
    var width = this.remoteVideo.nativeElement.clientWidth;
    var height = this.remoteVideo.nativeElement.clientHeight;
    var x, y;

    // Calculate normalized screen Values.
    // (0,0) in Streaming is Top-Left and (0,0) in Unity is Bottom-HS_FRONT_LEFT_0
    // so we invert it to match with Unity.
    x = evt.pointers[0].layerX / width;
    y = evt.pointers[0].layerY / height;

    this.controlService.tap(x, y, 0);
  }

}
