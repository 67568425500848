import { Directive, ElementRef, HostListener } from '@angular/core';
import { LibMonkeywayService } from 'lib-monkeyway';

const ZOOM_TRANSMIT_COOLDOWN_INTERVAL = 15;

@Directive({
  selector: '[appWheel]'
})
export class WheelDirective {

  private lastZoomTransmit;

  constructor(private el: ElementRef, private controlService: LibMonkeywayService) { }

  @HostListener('mousewheel', ['$event'])
  onMouseWheel(event: WheelEvent) {
    let wheelDelta = Math.max(-1, Math.min(1, (event.deltaY || -event.detail)));
    // console.log(wheelDelta);
    // console.log(event.deltaY);

    const now = new Date().getTime();
    if (this.lastZoomTransmit && ZOOM_TRANSMIT_COOLDOWN_INTERVAL > now - this.lastZoomTransmit) {
      return;
    }

    this.lastZoomTransmit = now;

    this.controlService.zoom(-wheelDelta);
  }
}
