import { Injectable } from '@angular/core';
import { StreamingService, StreamInfo } from 'streaming-lib';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { LibMonkeywayService } from 'lib-monkeyway';

interface MetaData {
  Port: number,
  publicDomainName: string
}

@Injectable({
  providedIn: 'root'
})
export class StreamService {

  constructor(private streaming: StreamingService,
    private controlService: LibMonkeywayService) { }

  public connected$ = new BehaviorSubject<boolean>(false);
  public stream$ = new BehaviorSubject<StreamInfo>(null);
  public error: string;

  private streamSubscription: Subscription;
  private streamInfo: StreamInfo;

  public async start(uri: string, connectionKey?: string): Promise<any> {
    if (this.streamSubscription) return;
    console.log('connecting');
    this.error = null;

    this.streaming.setBaseUrl(uri, '8c0cb995-a946-4db6-8e33-36ca139d2ba0'); // zoan.fi

    const connectionCode = this.TryParseInt(connectionKey, 0);
    if (connectionCode != 0) {
      connectionKey = await this.streaming.getConnectionKey(connectionCode);
    }

    this.streamSubscription = this.streaming.startStream(null, connectionKey).subscribe(info => {
      if (!info) {
        console.log('disconnected');

        this.stream$.next(null);
        this.connected$.next(false);

        if (this.streamSubscription) {
          this.error = null;
          this.streamSubscription.unsubscribe();
          this.streamSubscription = null;
        }
      } else if (!this.streamInfo || this.streamInfo.track !== info.track) {
        console.log('connected');
        console.log(info);

        // console.log('done connecting');
        // this.stream$.next(info);
        // this.connected$.next(true);
        const metaData = <MetaData>info.metaData;
        // info.ip
        this.controlService.connect(metaData.publicDomainName, metaData.Port)
          .subscribe(_ => {
            console.log('done connecting');
            this.stream$.next(info);
            this.connected$.next(true);
          }, async err => {
            console.error(`error connecting to renderer: ${err}`);
            this.error = 'connection to renderer failed';
            await this.stop();
          });
      }
      
    }, async error => {
      await this.stop();
      if (error instanceof HttpErrorResponse) {
        this.error = 'there is no streaming session available at the moment';
      } else {
        if (error instanceof Error) {
          this.error = (<Error>error).message;
        } else {
          this.error = 'connection to stream failed';
        }
      }

      console.error(error);
    });
  }

  public async stop() {
    if (this.streamSubscription) {
      await this.streaming.stopStream();
      this.error = null;
      this.controlService.close();
      // this.streamSubscription.unsubscribe();
      // this.streamSubscription = null;
    }
  }

  private TryParseInt(str: string, defaultValue: number): number {
    var retValue = defaultValue;
    if (str !== null) {
      if (str.length > 0) {
        const val = Number(str);
        if (!isNaN(val)) {
          retValue = val;
        }
      }
    }
    return retValue;
  }
}
