import { Component, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { StreamService,  } from './stream.service';
import { StreamInfo } from 'streaming-lib/lib/models/stream-info';

import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { PingService } from './ping.service';

export class MyHammerConfig extends HammerGestureConfig  {
  overrides = <any>{
      // override hammerjs default configuration
      'pan': {threshold: 3},
      'pinch': {threshold: 0},
      'tap': {
        taps: 1,
        interval: 300
      }
  }
}

@NgModule({
  providers:    [ { 
                    provide: HAMMER_GESTURE_CONFIG, 
                    useClass: MyHammerConfig 
                } ]
})

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Gorilla-Streaming Demo';
  currentPage:Number = 3;

  public stream$: Observable<StreamInfo> = this.streamService.stream$;
  public connected$: Observable<boolean> = this.streamService.connected$;

  constructor(private streamService: StreamService, private pingService: PingService) { 
    this.pingService.pingRegions();
  }
  
  logInDone(isLogInDone:boolean):void{
    this.currentPage=2;
  }

  startStreaming(startStreamingClicked:boolean):void{
    this.currentPage=3;
  }
  
  async ngOnInit() {
  }
}
