import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule }    from '@angular/common/http';
import { AppComponent } from './app.component';
import { LivestreamComponent } from './livestream/livestream.component';
import { RouterModule } from '@angular/router';
import { WheelDirective } from './wheel.directive';
import { PingService} from './ping.service';

@NgModule({
  declarations: [
    AppComponent,
    LivestreamComponent,
    WheelDirective
  ],
  imports: [
    BrowserModule,
    HttpClientModule
  ],
  providers: [
    PingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
