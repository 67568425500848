//our root app component
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, from, ReplaySubject } from 'rxjs';
import regionsJSON from './assets/regions.json';

export interface PingResult {
  ping: number;
  url: string;
  regions: string[];
}

@Injectable()
export class PingService {
  // pingStream: Subject<object> = new Subject<object>();
  // ping: number = 0;
  // sourceList: Array<object> = new Array<object>();
  // outputList: Array<string>;

  pingData$: ReplaySubject<PingResult[]> = new ReplaySubject<PingResult[]>();

  constructor(private http: HttpClient) {
  }

  public pingRegions() {
    let results = [];

    console.log('pinging regions');

    const requests = regionsJSON.regions.map(r => {
      const url = r['url'];
      const start = performance.now();

      const request = this.http.get(url, { responseType: 'text' });

      request.subscribe(() => {
        const end = performance.now();
        const ping = end - start;
        results.push({ region: r, ping: ping });
      });

      return request;
    });

    forkJoin(requests)
      .subscribe(_ => {
        results = results.sort((a, b) => {
          if (a.ping < b.ping) return -1;
          if (a.ping == b.ping) return 0;
          return 1;
        });
        console.log('done pinging regions');
        this.pingData$.next(results.map(r => <PingResult>{ ping: r.ping, url: r.region['url'], regions: r.region['name'] }));
      });
  }
}
